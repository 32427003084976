import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';
import {computed} from '@ember/object';

const Validations = buildValidations({
  name: {
    description: 'Promotion name',
    validators: [
      validator('presence', {
        presence: true,
        disabled: computed('model._isNameRequired', function() {
          return !this.get('model._isNameRequired');
        }),
        dependentKeys: ['model._isNameRequired'],
        message: 'Please add a promotion name'
      }),
      validator('length', {
        max: 18,
      }),
    ],
  },
});

export default class PromotionModel extends Model.extend(Validations) {
  @attr('number') bonus_id;
  @attr('number', { defaultValue: 0 }) order;
  @attr('string') name;
  @attr('number') status;
  @attr('string') type;
  @attr('date') updated_at;
  @attr('number') updated_by;

  @attr('boolean', { defaultValue: false }) _isPromotionUpdate;
  @attr('boolean', { defaultValue: false }) _isNameRequired;

  @belongsTo('bonus', { async: false, inverse: null }) bonus;
  @belongsTo('promotion-by-language', { async: false, inverse: null }) promotionByLanguage;

  @hasMany('promotion-by-language', { async: false, inverse: null }) promotionByLanguages;

  get bonusCode() {
    if (this.bonus && this.bonus.get('bonus_code')) {
      return this.bonus.get('bonus_code');
    }
    return null;
  }

  get title() {
    if (this.bonus && this.bonus.get('bonus_code')) {
      return this.bonus.get('bonus_code');
    }
    return null;
  }

  toJSON() {
    return {
      bonus_id: this.bonus_id,
      name: this.name,
      type: this.type,
      status: this.status,
      order: this.order,
    };
  }
}
